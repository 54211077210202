document.addEventListener("DOMContentLoaded", function () {
    const cards = document.querySelectorAll(".bg-card-etapa");

    cards.forEach((card, index) => {
        card.addEventListener("mouseover", function () {
            // Remove a classe 'active' de todos os cartões
            cards.forEach((c) => c.classList.remove("active"));

            // Adiciona a classe 'active' ao cartão que está sendo hover
            this.classList.add("active");
        });

        card.addEventListener("mouseout", function () {
            // Adiciona a classe 'active' apenas ao primeiro elemento
            if (index === 0) {
                this.classList.add("active");
            }
        });
    });

    var btnAjudaElements = document.querySelectorAll(".btn-ajuda");

    // Adicionar um evento de clique a cada elemento encontrado
    btnAjudaElements.forEach(function (btnAjudaElement) {
        btnAjudaElement.addEventListener("click", function () {
            // Atualizar o atributo 'aria-expanded' para 'false' em todos os elementos com a classe 'btn-ajuda'
            btnAjudaElements.forEach(function (element) {
                element.setAttribute("aria-expanded", "false");
                element.classList.remove("show", "collapsed");
                element
                    .querySelectorAll(".btn .collapse")
                    .forEach(function (collapseElement) {
                        collapseElement.classList.remove("collapsed", "show");
                    });
            });

            this.classList.add("collapsed", "show");
            this.setAttribute("aria-expanded", "true");
            // Atualizar o atributo 'aria-expanded' para 'true' no elemento clicado
        });
    });

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            // Ajuste o valor abaixo conforme necessário para controlar o espaçamento
            const offset = 150; // Pixels

            const targetId = this.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offsetTop =
                    targetElement.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: offsetTop - offset,
                    behavior: "smooth",
                });
            }
        });
    });
});

window.addEventListener("scroll", function () {
    var navbar = document.getElementById("menu");
    var banners = document.getElementById("carousel-banners");
    if (window.scrollY >= 100) {
        navbar.classList.add("scrolled");
        banners.style.paddingTop = "100px";
        navbar.style.transition = "background-color 0.3s ease, padding-top 0.5s ease";
    } else {
        navbar.classList.remove("scrolled");
        banners.style.paddingTop = "0"
        navbar.style.transition = "background-color 0.3s ease, padding-top 0.5s ease";
    }
});

$("#carousel-programas").owlCarousel({
    loop: false,
    rewind: true,
    autoplay: true,
    margin: 10,
    items: 2,
    nav: true,
    autoplayHoverPause: true,
    dots: false,
    stageClass: "owl-stage d-flex",
    navText: [
        '<span class="p-2 d-block"><<</span>',
        '<span class="p-2 d-block">>></span>',
    ],
    responsive: {
        0: {
            items: 1,
        },
        575: {
            items: 1,
            autoHeight: true,
        },
        1500: {
            items: 2,
        },
    },
});

$("#carousel-pertencer").owlCarousel({
    loop: false,
    rewind: true,
    autoplay: true,
    margin: 10,
    items: 1,
    nav: false,
    autoplayHoverPause: true,
    dots: false,
    stageClass: "owl-stage",
    navText: [
        '<span class="p-2 d-block"><<</span>',
        '<span class="p-2 d-block">>></span>',
    ],
});

$(".contagem").each(function () {
    var targetValue = parseFloat($(this).text());

    $(this)
        .prop("Counter", 0)
        .animate({
            Counter: targetValue,
        }, {
            duration: 10000,
            easing: "swing",
            step: function (now) {
                // Se o valor for um inteiro, arredonde para cima
                var displayedValue = Number.isInteger(targetValue) ?
                    Math.ceil(now) :
                    now.toLocaleString("en-US", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                    });
                $(this).text(displayedValue);
            },
        });
});

$("#carousel-depoimentos").owlCarousel({
    loop: false,
    rewind: true,
    autoplay: true,
    margin: 10,
    items: 1,
    nav: true,
    autoplayHoverPause: true,
    dots: false,
    stageClass: "owl-stage d-flex",
    navText: [
        '<span class="p-2 d-block"><<</span>',
        '<span class="p-2 d-block">>></span>',
    ],
});

$("#carousel-valores").owlCarousel({
    loop: false,
    rewind: true,
    autoplay: true,
    margin: 10,
    items: 7,
    nav: true,
    autoplayHoverPause: true,
    dots: false,
    navText: [
        '<span class="p-2 d-block"><<</span>',
        '<span class="p-2 d-block">>></span>',
    ],
    responsive: {
        0: {
            items: 1,
        },
        500: {
            items: 1,
        },
        600: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1200: {
            items: 4,
        },
        1500: {
            items: 5,
        },
        1700: {
            items: 6,
        },
        2000: {
            items: 7,
        },
    },
});

$("#carousel-banners").owlCarousel({
    loop: false,
    rewind: true,
    autoplay: true,
    margin: 0,
    items: 1,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    navText: [
        '<span class="p-2 d-block"><<</span>',
        '<span class="p-2 d-block">>></span>',
    ],
});

$(document).on("scroll", function () {
    if ($(document).scrollTop() > 86) {
        $("#banner").addClass("shrink");
    } else {
        $("#banner").removeClass("shrink");
    }
});

$("#menu .dropdown").on("click", function () {
    $(this)
        .find(".dropdown-menu")
        .removeAttr("data-bs-popper")
        .stop(true, true)
        .delay(500);
});
